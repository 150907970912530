:root {
  --clight: hsl(0, 0.5%, 99.83%);
  --slate: #7D93A0;
  --gold: #BF8828;
  --ngrey: #7D8696;
  --ocean-blue: #264969;
}


*, ::before, ::after {
  box-sizing: border-box;
}

#root {
  min-height: 100dvh;
  background: var(--clight);
}

main {
   background: var(--clight); 
}

header {
  min-height: 5rem;
  /* background: var(--clight); */
  /* border-bottom: 1px solid var(--ocean-blue); */
  /* box-shadow:  -1rem -1rem var(--ocean-blue);  */
}

.navbar-brand img{
  width: 180px;
  height: auto;
} 

/* nav-profile-link {
  
} */
.nav-profile-link img{
  
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
}

footer {
  min-height: 5rem;
  background: var(--clight);
  color: var(--ocean-blue);
}

footer i {
  font-size: 2rem;
}
footer li{
  line-height: 2.2rem;
}

.full-height {
  min-height: 100%;
}


.landing-image-container {
  background-size: cover;
  min-height: 65vh;
  background: url('/public/pexels-bakr-magrabi-3203659.jpg') no-repeat center center;
}

.landing-image{
  
  width:100%;
  height: auto;
}

.account-card {
width: 79px !important;
height: 50px !important;
}

.App {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .flex-fill {
  flex: 1 1 auto;
}
 */

.card-photo {
  border-radius: 0.4rem;
  position: relative;
  margin-top: -30px;
  width:fit-content;
  box-shadow: hsla(0, 0%, 29%, 50%) 0.3rem 0.3rem 0.4rem;
    

}

.card-photo img { 
  border-radius: 0.3rem;
  
}


.table-col-first {
  width: 1rem;
}

.chart-card-icon {
  position:relative;
  width: 5rem;
  height: 4rem;
  border-radius: 3px;
  box-shadow: 
    inset hsla(0, 0%, 94%, 20%) 0.5rem 0.5rem 0.7rem,
    hsla(0, 0%, 29%, 50%) 0.3rem 0.3rem 0.4rem;
    
  margin-left: 1.5rem;
  margin-top: -1rem;
  font-size: 2.2rem;
  color: hsl(208, 100%, 97%);
}

.page-error,
.server-error {
  
  background-size: cover;
  min-height: 65vh;
}

.page-error {
  background: url('/public/pexels-bakr-magrabi-3203659.jpg') no-repeat center center;
}

.server-error {
  background: url('/public/pexels-manuel-geissinger-9101887.jpg') no-repeat center center;
}

.error-page-message {
  background:hsla(0, 0%, 50%, 40%);
  border-radius: 0.7rem;
}